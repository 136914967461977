import { Carousel, Heading, Picture, TextLink } from '@troon/ui';
import { For } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { getConfigValue } from '../../../modules/config';

export default function TroonPartnersNetworkServicesPage() {
	return (
		<>
			<NoHydration>
				<Heading as="h2">Discover Troon Partners Network</Heading>
				<p>
					Through the Troon Partners Network, our mission is to connect companies with the world-renowned Troon brand
					and the millions of golfers across our network of properties. Companies large, small, and across all business
					sectors leverage Troon’s unique marketing platform to optimize exposure, reach, and brand recognition while
					enjoying unparalleled golf lifestyle and hospitality opportunities.
				</p>

				<div>
					<Heading as="h2" size="p" class="text-brand-600">
						Programs Overview
					</Heading>
					<p class="text-2xl font-semibold md:text-3xl">
						Leverage Opportunities in Golf Through Customizable and Scalable Partnership Programs.
					</p>
				</div>

				<figure class="group relative flex flex-col gap-1">
					<div class="overflow-hidden rounded">
						<Picture
							src={`${getConfigValue('IMAGE_HOST')}/digital/photo/troon-network-program-overview.jpg`}
							alt="Troon Partners Network Programs Overview slideshow preview"
							sizes={[
								[480, 280],
								[1024, 640],
							]}
							crop="center"
							class="object-contain group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-200"
						/>
					</div>
					<figcaption class="text-center">
						View the{' '}
						<TextLink
							href="https://www.canva.com/design/DAFyZyLqDmM/Q2hCZ0XcsTAObV4Hogb1FQ/view"
							class="after:absolute after:inset-0"
						>
							Troon Partners Network Programs Overview
						</TextLink>
						.
					</figcaption>
				</figure>

				<div>
					<Heading as="h2" size="p" class="text-brand-600">
						Our Partners
					</Heading>
					<p class="text-2xl font-semibold md:text-3xl">Collaborating with Iconic Brands Across the World</p>
				</div>
			</NoHydration>

			<div class="rounded bg-neutral-200 p-4">
				<Carousel>
					<For each={partners}>
						{(partner) => (
							<Carousel.Item>
								<Picture src={partner.src} alt={partner.alt} sizes={[[200, 200]]} mode="contain" crop="center" />
							</Carousel.Item>
						)}
					</For>
				</Carousel>
			</div>

			<NoHydration>
				<div>
					<Heading as="h2">Worldwide Leader</Heading>
					<p class="font-semibold text-brand-600">in Golf and Club Management</p>
				</div>

				<ul class="grid grid-cols-3 gap-x-6 gap-y-12 font-semibold md:gap-x-24 md:text-lg">
					<li class="flex flex-col gap-2 border-s-4 border-s-brand ps-2 md:ps-8">
						<span class="text-2xl md:text-5xl">700K+</span>
						<span>Engaged email database</span>
					</li>
					<li class="flex flex-col gap-2 border-s-4 border-s-brand ps-2 md:ps-8">
						<span class="text-2xl md:text-5xl">250K+</span>
						<span>Private club members</span>
					</li>
					<li class="flex flex-col gap-2 border-s-4 border-s-brand ps-2 md:ps-8">
						<span class="text-2xl md:text-5xl">860K+</span>
						<span>Troon Rewards members</span>
					</li>
					<li class="flex flex-col gap-2 border-s-4 border-s-brand ps-2 md:ps-8">
						<span class="text-2xl md:text-5xl">200K+</span>
						<span>Social media followers</span>
					</li>
					<li class="col-span-2 flex flex-col gap-2 border-s-4 border-s-brand ps-2 md:ps-8">
						<span class="text-2xl text-brand-600 md:text-5xl">15M+</span>
						<span>Golf Rounds Played Per Year on Troon-affiliated Courses</span>
					</li>
				</ul>
			</NoHydration>
		</>
	);
}

export const route = {
	info: {
		hasHero: true,
		title: 'Troon Partners Network',
		description: 'Establish a brand identity among golf’s most influential audience.',
		hero: `${getConfigValue('IMAGE_HOST')}/digital/hero/troon-partners-network-2024-11-04.jpg`,
	},
};

const partners: Array<{ src: string; alt: string }> = [
	{ src: `${getConfigValue('IMAGE_HOST')}/digital/logos/callaway.png`, alt: 'callaway logo' },
	{ src: `${getConfigValue('IMAGE_HOST')}/digital/logos/golf-week.png`, alt: 'golf-week logo' },
	{ src: `${getConfigValue('IMAGE_HOST')}/digital/logos/molson-coors.png`, alt: 'molson-coors logo' },
	{ src: `${getConfigValue('IMAGE_HOST')}/digital/logos/nbc-sports-next.png`, alt: 'nbc-sports-next logo' },
	{ src: `${getConfigValue('IMAGE_HOST')}/digital/logos/nike.png`, alt: 'nike logo' },
	{ src: `${getConfigValue('IMAGE_HOST')}/digital/logos/pepsi.png`, alt: 'pepsi logo' },
	{ src: `${getConfigValue('IMAGE_HOST')}/digital/logos/points-bet.png`, alt: 'points-bet logo' },
	{ src: `${getConfigValue('IMAGE_HOST')}/digital/logos/shot-scope.png`, alt: 'shot-scope logo' },
	{ src: `${getConfigValue('IMAGE_HOST')}/digital/logos/topgolf.png`, alt: 'topgolf logo' },
	{ src: `${getConfigValue('IMAGE_HOST')}/digital/logos/visa.png`, alt: 'visa logo' },
];
